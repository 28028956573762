<template>
  <div>
    <v-app>
      <v-content>
        <v-card class="overflow-hidden mx-auto">
          <Navbar v-scroll="onScroll" />
          <v-content>
            <section class="mx-auto" fixed-tabs>
              <v-img
                v-if="isbgDesktop"
                :src="require('../../assets/img/navbarbottom.jpg')"
                fixed-tabs
                class="white--text"
              >
                <v-container fill-height>
                  <v-row class="mx-auto text-left mb-5">
                    <v-col cols="12" md="12">
                      <div class="slide-right">
                        <h2>นโยบายการคุ้มครองข้อมูลส่วนบุคคล</h2>
                        <h2>Data Protection Policy</h2>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-img>
            </section>
            <section class="mx-auto" fixed-tabs>
              <v-img
                v-if="isbgMobile"
                :src="require('../../assets/img/bgsizemobile.jpg')"
                fixed-tabs
                class="white--text"
              >
                <v-container fill-height>
                  <v-row class="mx-auto text-left mb-5">
                    <v-col cols="12" md="12">
                      <h2 class="animated fadeInUp ease-out-circ d2 a-1 f2 fw3">
                        นโยบายการคุ้มครอง
                        <br />ข้อมูลส่วนบุคคล
                      </h2>
                      <h2>Data Protection Policy</h2>
                    </v-col>
                  </v-row>
                </v-container>
              </v-img>
            </section>
            <section class="white" style="color:#1A237E">
              <v-container>
                <v-row no-gutters>
                  <v-col class="text-center pa-5" cols="12" md="12">
                    <v-container grid-list-xs>
                      <h2>บริษัท อินเทอร์เน็ตประเทศไทย จำกัด (มหาชน)</h2>
                      <br />
                      <v-divider></v-divider>
                      <br />
                      <div class="text-left mb-5">
                        <card-text color="white">
                          มีความมุ่งมั่นที่จะดำเนินการตาม พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
                          พ.ศ. 2562 ให้สอดคล้องกับหลักเกณฑ์ของคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล
                          เพื่อให้มีหลักเกณฑ์การคุ้มครองสิทธิของเจ้าของข้อมูลเกี่ยวกับข้อมูลส่วนบุคคล
                          สิทธิในความเป็นส่วนตัวและเสรีภาพในข้อมูลส่วนบุคคลของผู้เจ้าของข้อมูล
                          และพัฒนาปรับปรุงนโยบายระเบียบปฏิบัติของบริษัทให้ต่อเนื่องสืบไป
                          เพื่อให้เป็นไปตามนโยบายการคุ้มครองข้อมูลส่วนบุคคล
                        </card-text>
                      </div>
                    </v-container>
                  </v-col>
                </v-row>
                <br />
              </v-container>
            </section>
            <section class="indigo lighten-5 pt-7 pb-7">
              <v-container>
                <v-row no-gutters>
                  <v-col class="text-left pa-12" cols="12" sm="3">
                    <v-row class="fill-height" align="center" justify="center">
                      <v-col cols="12">
                        <h2>คำนิยามบริษัท</h2>
                        <br />
                        <v-divider></v-divider>
                        <br />
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" sm="9" class="fill-height">
                    <v-row>
                      <v-col cols="12" sm="4">
                        <sequential-entrance fromRight delay="100">
                          <v-card class="mx-auto" max-width="300" :height="230">
                            <v-card-text>
                              <v-layout row wrap fill-height align-center justify-center>
                                <v-layout row wrap justify-center align-center>
                                  <v-flex xs10 lg10 class="text-left">
                                    <v-container>
                                      <h3>“ บริษัท ”</h3>
                                    </v-container>
                                  </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-right align-right>
                                  <v-flex xs11 lg12 class="text-right">
                                    <v-btn
                                      color="purple lighten-1"
                                      fab
                                      x-large
                                      dark
                                      class="pulsing"
                                    >
                                      <v-icon>mdi-city-variant-outline</v-icon>
                                    </v-btn>
                                  </v-flex>
                                </v-layout>
                              </v-layout>
                              <br />
                              <p>หมายถึง</p>
                              <div class="text--primary">
                                บริษัท อินเทอร์เน็ตประเทศไทย
                                <br />จำกัด (มหาชน)
                              </div>
                            </v-card-text>
                          </v-card>
                        </sequential-entrance>
                        <br />
                        <sequential-entrance fromRight delay="100">
                          <v-card class="mx-auto" max-width="300" align="left" justify="left">
                            <v-card-text>
                              <v-layout row wrap fill-height align-center justify-center>
                                <v-layout row wrap justify-center align-center>
                                  <v-flex xs10 lg10 class="text-left">
                                    <v-container>
                                      <h3>“ ข้อมูลส่วนบุคคล ”</h3>
                                    </v-container>
                                  </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-right align-right>
                                  <v-flex xs11 lg12 class="text-right">
                                    <v-btn color="amber lighten-1" fab x-large dark class="pulsing">
                                      <v-icon>mdi-card-account-details-star-outline</v-icon>
                                    </v-btn>
                                  </v-flex>
                                </v-layout>
                              </v-layout>
                              <p>หมายถึง</p>
                              <div class="text--primary">
                                ข้อมูลใดๆที่ระบุไปถึง “เจ้าของข้อมูล”
                                <br />ได้ไม่ว่าทางตรงหรือทางอ้อม
                              </div>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn color="indigo darken-4" text>อ่านเพิ่มเติม</v-btn>

                              <v-spacer></v-spacer>

                              <v-btn icon @click="showDataOne = !showDataOne">
                                <v-icon>{{ showDataOne ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                              </v-btn>
                            </v-card-actions>

                            <v-expand-transition>
                              <div v-show="showDataOne">
                                <v-divider></v-divider>
                                <v-card-text>
                                  อาทิ ชื่อ นามสกุล ที่อยู่ หมายเลขโทรศัพท์
                                  <br />เลขประจำตัวประชาชน เลขหนังสือเดินทาง เป็นต้น
                                  ทั้งนี้ ไม่รวมถึงข้อมูลดังต่อไปนี้ เช่น
                                  <br />ข้อมูลสำหรับการติดต่อทางธุรกิจที่ไม่ได้ระบุถึงตัวบุคคล
                                  อาทิ ชื่อบริษัท ที่อยู่ของบริษัท
                                  <br />เลขทะเบียนนิติบุคคลของบริษัท
                                  <br />เช่น info@company.co.th เป็นต้น
                                </v-card-text>
                              </div>
                            </v-expand-transition>
                          </v-card>
                        </sequential-entrance>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <sequential-entrance fromRight delay="100">
                          <v-card class="mx-auto" max-width="300" :height="230">
                            <v-card-text>
                              <v-layout row wrap fill-height align-center justify-center>
                                <v-layout row wrap justify-center align-center>
                                  <v-flex xs10 lg10 class="text-left">
                                    <v-container>
                                      <h3>“ บุคคล ”</h3>
                                    </v-container>
                                  </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-right align-right>
                                  <v-flex xs11 lg12 class="text-right">
                                    <v-btn color="red lighten-1" fab x-large dark class="pulsing">
                                      <v-icon>mdi-account-tie-outline</v-icon>
                                    </v-btn>
                                  </v-flex>
                                </v-layout>
                              </v-layout>
                              <p>หมายถึง</p>
                              <div class="text--primary">
                                บุคคลธรรมดาที่มีชีวิตอยู่
                                <br />ไม่รวมถึง “นิติบุคคล”
                                <br />ที่จัดตั้งขึ้นตามกฎหมาย
                              </div>
                            </v-card-text>
                          </v-card>
                        </sequential-entrance>
                        <br />
                        <sequential-entrance fromRight delay="100">
                          <v-card class="mx-auto" max-width="300" align="left" justify="left">
                            <v-card-text>
                              <v-layout row wrap fill-height align-center justify-center>
                                <v-layout row wrap justify-center align-center>
                                  <v-flex xs10 lg10 class="text-left">
                                    <v-container>
                                      <h3>
                                        “ เจ้าของ
                                        <br />ข้อมูลส่วนบุคคล ”
                                      </h3>
                                    </v-container>
                                  </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-right align-right>
                                  <v-flex xs11 lg12 class="text-right">
                                    <v-btn color="green accent-3" fab x-large dark class="pulsing">
                                      <v-icon>mdi-hail</v-icon>
                                    </v-btn>
                                  </v-flex>
                                </v-layout>
                              </v-layout>
                              <p>หมายถึง</p>
                              <div class="text--primary">
                                บุคคลที่ข้อมูลส่วนบุคคลนั้นระบุไปถึง
                                <br />ตัวบุคคลที่เป็นเจ้าของข้อมูลส่วนบุคคลนั้น
                              </div>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn color="indigo darken-4" text>อ่านเพิ่มเติม</v-btn>

                              <v-spacer></v-spacer>

                              <v-btn icon @click="showDataTwo = !showDataTwo">
                                <v-icon>{{ showDataTwo ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                              </v-btn>
                            </v-card-actions>

                            <v-expand-transition>
                              <div v-show="showDataTwo">
                                <v-divider></v-divider>
                                <v-card-text>
                                  แต่ไม่ใช่กรณีที่บุคคลมีความเป็นเจ้าของข้อมูล
                                  <br />(Ownership) หรือเป็นผู้สร้างหรือเก็บ
                                  รวบรวมข้อมูลนั้นเอง
                                  โดยเจ้าของข้อมูลส่วนบุคคลนี้จะหมายถึงบุคคลธรรมดาเท่านั้น
                                  และไม่รวมถึง “นิติบุคคล” (Juridical Person)
                                  <br />ที่จัดตั้งขึ้นตามกฎหมาย
                                  <br />เช่น บริษัท สมาคม มูลนิธิ หรือองค์กรอื่นใด
                                </v-card-text>
                              </div>
                            </v-expand-transition>
                          </v-card>
                        </sequential-entrance>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <sequential-entrance fromRight delay="100">
                          <v-card class="mx-auto" max-width="300" :height="230">
                            <v-card-text>
                              <v-layout row wrap fill-height align-center justify-center>
                                <v-layout row wrap justify-center align-center>
                                  <v-flex xs10 lg10 class="text-left">
                                    <v-container>
                                      <h3>
                                        “ ข้อมูลส่วน
                                        <br />บุคคลอ่อนไหว ”
                                      </h3>
                                    </v-container>
                                  </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-right align-right>
                                  <v-flex xs11 lg12 class="text-right">
                                    <v-btn color="pink lighten-2" fab x-large dark class="pulsing">
                                      <v-icon>mdi-account-heart-outline</v-icon>
                                    </v-btn>
                                  </v-flex>
                                </v-layout>
                              </v-layout>
                              <p>หมายถึง</p>
                              <div class="text--primary">
                                ข้อมูลที่เป็นเรื่องส่วนบุคคลโดยแท้ของบุคคล
                                แต่มีความละเอียดอ่อนและอาจสุ่มเสี่ยง
                                ในการเลือกปฏิบัติอย่างไม่เป็นธรรม
                                <br />เช่น เชื้อชาติ เผ่าพันธุ์ เป็นต้น
                              </div>
                            </v-card-text>
                          </v-card>
                        </sequential-entrance>
                        <br />
                        <sequential-entrance fromRight delay="100">
                          <v-card class="mx-auto" max-width="300" align="left" justify="left">
                            <v-card-text>
                              <v-layout row wrap fill-height align-center justify-center>
                                <v-layout row wrap justify-center align-center>
                                  <v-flex xs10 lg10 class="text-left">
                                    <v-container>
                                      <h3>“ การประมวลผล ”</h3>
                                    </v-container>
                                  </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-right align-right>
                                  <v-flex xs11 lg12 class="text-right">
                                    <v-btn color="teal lighten-1 " fab x-large dark class="pulsing">
                                      <v-icon>mdi-cogs</v-icon>
                                    </v-btn>
                                  </v-flex>
                                </v-layout>
                              </v-layout>
                              <p>หมายถึง</p>
                              <div class="text--primary">
                                การดำเนินการใดๆ เกี่ยวกับการใช้
                                <br />การเปิดเผย หรือการแก้ไข
                              </div>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn color="indigo darken-4" text>อ่านเพิ่มเติม</v-btn>

                              <v-spacer></v-spacer>

                              <v-btn icon @click="showDataThree = !showDataThree">
                                <v-icon>{{ showDataThree ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                              </v-btn>
                            </v-card-actions>

                            <v-expand-transition>
                              <div v-show="showDataThree">
                                <v-divider></v-divider>
                                <v-card-text>
                                  ซึ่งข้อมูลส่วนบุคคลของเจ้าของข้อมูล
                                  <br />ไม่ว่าจะใช้วิธีการใดๆ และให้หมายความ
                                  <br />รวมถึงการส่งหรือโอนข้อมูลส่วนบุคคล
                                  <br />ที่ใช้ในการประกอบกิจการนั้นๆ
                                </v-card-text>
                              </div>
                            </v-expand-transition>
                          </v-card>
                        </sequential-entrance>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </section>
            <section class="white pt-3 pb-5" style="color:#1A237E">
              <v-container grid-list-xs>
                <div justify="center" align="center">
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-container grid-list-xs>
                        <h2>การเก็บรวบรวม และวัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคล</h2>
                        <div class="text-left">
                          บริษัทใช้วิธีการเก็บรวบรวม ใช้ เปิดเผย ข้อมูลส่วนบุคคลด้วยวิธีการที่ชอบด้วยกฎหมายและเป็นธรรม
                          โดยจัดเก็บข้อมูลส่วนบุคคลเท่าที่จำเป็น ภายใต้วัตถุประสงค์ในการดำเนินงานของบริษัท
                          หรือตามที่กฎหมายกำหนดเท่านั้น ซึ่งหากมีการเปลี่ยนแปลงวัตถุประสงค์
                          บริษัทจะแจ้งให้เจ้าของข้อมูลส่วนบุคคลทราบก่อนหรือขณะเก็บรวบรวมข้อมูลส่วนบุคคล
                          และบันทึกเพิ่มเติมไว้เป็นหลักฐาน โดยมีรายละเอียดดังนี้
                        </div>
                      </v-container>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="4">
                      <v-container grid-list-xs>
                        <sequential-entrance fromRight delay="100">
                          <v-card
                            class="mx-auto"
                            max-width="370"
                            max-height="200"
                            style="color:#1A237E"
                          >
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="1">
                                  <v-icon color="indigo darken-4">mdi-circle-slice-1</v-icon>
                                </v-col>
                                <v-col
                                  cols="12"
                                  sm="11"
                                  class="text-left"
                                >วัตถุประสงค์ของการเก็บรวบรวม</v-col>
                              </v-row>
                            </v-container>
                          </v-card>
                        </sequential-entrance>
                      </v-container>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-container grid-list-xs>
                        <sequential-entrance fromRight delay="100">
                          <v-card
                            class="mx-auto"
                            max-width="370"
                            max-height="200"
                            style="color:#1A237E"
                          >
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="1">
                                  <v-icon color="indigo darken-4">mdi-circle-slice-2</v-icon>
                                </v-col>
                                <v-col
                                  cols="12"
                                  sm="11"
                                  class="text-left"
                                >ข้อมูลส่วนบุคคลที่ทำการเก็บรวบรวม</v-col>
                              </v-row>
                            </v-container>
                          </v-card>
                        </sequential-entrance>
                      </v-container>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-container grid-list-xs>
                        <sequential-entrance fromRight delay="100">
                          <v-card
                            class="mx-auto"
                            max-width="370"
                            max-height="200"
                            style="color:#1A237E"
                          >
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="1">
                                  <v-icon color="indigo darken-4">mdi-circle-slice-3</v-icon>
                                </v-col>
                                <v-col cols="12" sm="11" class="text-left">สิทธิของเจ้าของข้อมูล</v-col>
                              </v-row>
                            </v-container>
                          </v-card>
                        </sequential-entrance>
                      </v-container>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="4">
                      <v-container grid-list-xs>
                        <sequential-entrance fromRight delay="100">
                          <v-card
                            class="mx-auto"
                            max-width="370"
                            max-height="200"
                            :height="169"
                            style="color:#1A237E"
                          >
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="1">
                                  <v-icon color="indigo darken-4">mdi-circle-slice-4</v-icon>
                                </v-col>
                                <v-col
                                  cols="12"
                                  sm="11"
                                  class="text-left"
                                >ประเภทของบุคคลหรือหน่วยงานซึ่งข้อมูลส่วนบุคคลที่เก็บรวบรวมอาจถูกเปิดเผย</v-col>
                              </v-row>
                            </v-container>
                          </v-card>
                        </sequential-entrance>
                      </v-container>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-container grid-list-xs>
                        <sequential-entrance fromRight delay="100">
                          <v-card
                            class="mx-auto"
                            max-width="370"
                            max-height="200"
                            style="color:#1A237E"
                          >
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="1">
                                  <v-icon color="indigo darken-4">mdi-circle-slice-5</v-icon>
                                </v-col>
                                <v-col cols="12" sm="11" class="text-left">
                                  กรณีที่เจ้าของข้อมูลต้องให้ข้อมูลส่วนบุคคล
                                  เพื่อปฏิบัติตามกฎหมายหรือสัญญาหรือเพื่อเข้าทำสัญญาโดยต้องแจ้งถึงผลกระทบที่เป็นไปได้จากการไม่ให้ข้อมูลส่วนบุคคลให้เจ้าของข้อมูลทราบด้วย
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card>
                        </sequential-entrance>
                      </v-container>
                    </v-col>
                    <v-col cols="12" sm="4" justify="center" align="center">
                      <v-container grid-list-xs>
                        <sequential-entrance fromRight delay="100">
                          <v-img :src="require('../../assets/img/image4.png')" width="50%" />
                        </sequential-entrance>
                      </v-container>
                    </v-col>
                  </v-row>
                </div>
              </v-container>
            </section>
            <section class="indigo lighten-5 pt-3 pb-3">
              <v-container grid-list-xs style="color:#1A237E">
                <div justify="center" align="center">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-row>
                        <v-col cols="12" sm="12" align="left" justify="left">
                          <v-container grid-list-xs>
                            <h2>การใช้หรือการเปิดเผยข้อมูลส่วนบุคคล</h2>การใช้หรือการเปิดเผยข้อมูลส่วนบุคคล บริษัทจะต้องดำเนินการตามความจำเป็น
                            และตรงกับวัตถุประสงค์ของการเก็บรวบรวมข้อมูล และจะต้องได้รับความยินยอม
                            จากเจ้าของข้อมูลจากเจ้าของข้อมูลที่ให้ไว้ก่อนหรือในขณะนั้น
                            เว้นแต่กรณีดังต่อไปนี้
                            ไม่จำเป็นต้องขอความยินยอม
                          </v-container>
                        </v-col>
                      </v-row>
                      <v-row style="margin-top:68px">
                        <v-col cols="12" sm="6">
                          <v-container grid-list-xs>
                            <v-card class="mx-auto" max-width="350" :height="200">
                              <v-container>
                                <v-row>
                                  <v-col cols="12" sm="2">
                                    <v-icon large color="indigo darken-4">mdi-shield-sun-outline</v-icon>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="10"
                                    style="color:#1A237E"
                                    class="text-left"
                                  >เพื่อป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย หรือสุขภาพของบุคคล</v-col>
                                </v-row>
                              </v-container>
                            </v-card>
                          </v-container>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-container grid-list-xs>
                            <v-card class="mx-auto" max-width="350" :height="200">
                              <v-container>
                                <v-row>
                                  <v-col cols="12" sm="2">
                                    <v-icon large color="indigo darken-4">mdi-shield-star-outline</v-icon>
                                  </v-col>
                                  <v-col cols="12" sm="10" style="color:#1A237E" class="text-left">
                                    เพื่อประโยชน์ในการศึกษาวิจัยหรือสถิติซึ่งได้จัดให้มีมาตรการปกป้องที่เหมาะสม
                                    เพื่อคุ้มครองสิทธิและเสรีภาพของเจ้าของข้อมูลส่วนบุคคล
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card>
                          </v-container>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-container grid-list-xs>
                            <v-card class="mx-auto" max-width="365" :height="200">
                              <v-container>
                                <v-row>
                                  <v-col cols="12" sm="2">
                                    <v-icon large color="indigo darken-4">mdi-shield-home-outline</v-icon>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="10"
                                    style="color:#1A237E"
                                    class="text-left"
                                  >เพื่อปฏิบัติหน้าที่ตามกฎหมายหรือตามคำสั่งศาล</v-col>
                                </v-row>
                              </v-container>
                            </v-card>
                          </v-container>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-container grid-list-xs>
                            <v-card class="mx-auto" max-width="365" :height="200">
                              <v-container>
                                <v-row>
                                  <v-col cols="12" sm="2">
                                    <v-icon large color="indigo darken-4">mdi-shield-account-outline</v-icon>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="10"
                                    style="color:#1A237E"
                                    class="text-left"
                                  >เพื่อประโยชน์สาธารณะของผู้ควบคุมโดยชอบด้วยกฎหมาย</v-col>
                                </v-row>
                              </v-container>
                            </v-card>
                          </v-container>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-container grid-list-xs>
                            <v-card class="mx-auto" max-width="365" :height="200">
                              <v-container>
                                <v-row>
                                  <v-col cols="12" sm="2">
                                    <v-icon large color="indigo darken-4">mdi-shield-lock-outline</v-icon>
                                  </v-col>
                                  <v-col cols="12" sm="10" style="color:#1A237E" class="text-left">
                                    เพื่อประโยชน์ในการสืบสวนของพนักงานเจ้าหน้าที่ตามกฎหมาย
                                    หรือในการพิจารณาพิพากษาคดีของศาล
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card>
                          </v-container>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-container grid-list-xs>
                            <v-card class="mx-auto" max-width="365" :height="200">
                              <v-container>
                                <v-row>
                                  <v-col cols="12" sm="2">
                                    <v-icon large color="indigo darken-4">mdi-shield-check-outline</v-icon>
                                  </v-col>
                                  <v-col cols="12" sm="10" style="color:#1A237E" class="text-left">
                                    เพื่อความจำเป็นเพื่อการปฏิบัติตามสัญญาซึ่งเจ้าของข้อมูลส่วนบุคคลเป็นคู่สัญญาหรือ
                                    เพื่อใช้ในการดำเนินการตามคำขอของเจ้าของข้อมูลส่วนบุคคลก่อนเข้าทำสัญญานั้น
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card>
                          </v-container>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </v-container>
            </section>
            <section class="white pt-7" style="color:#1A237E">
              <v-container>
                <div justify="center" align="center">
                  <v-row dense>
                    <v-col cols="12" sm="6">
                      <v-row class="pb-12">
                        <v-col cols="12" sm="2">
                          <v-btn color="white" fab x-large>
                            <v-icon x-large color="indigo darken-4">mdi-star-circle-outline</v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="12" sm="10" class="text-left">
                          <v-container grid-list-xs>
                            <h3>มาตรการรักษาความมั่นคงปลอดภัย</h3>จัดให้มีมาตรการรักษาความมั่นคงปลอดภัยที่เหมาะสม
                            เพื่อป้องกันการสูญหาย เข้าถึง ใช้ เปลี่ยนแปลง แก้ไข
                            หรือเปิดเผยข้อมูลส่วนบุคคลโดยปราศจากอำนาจหรือโดยมิชอบ
                          </v-container>
                        </v-col>
                      </v-row>
                      <v-row class="pb-5 pt-5">
                        <v-col cols="12" sm="2">
                          <v-btn color="white" fab x-large>
                            <v-icon x-large color="indigo darken-4">mdi-star-circle-outline</v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="12" sm="10" class="text-left">
                          <v-container grid-list-xs>
                            <h3>การกำหนดขั้นตอน</h3>จัดให้มีการกำหนดขั้นตอน
                            ระเบียบในกรณีที่ต้องให้ข้อมูลส่วนบุคคลแก่บุคคลหรือนิติบุคคลอื่นที่ไม่ใช่ผู้ควบคุมข้อมูลส่วนบุคคล
                            ต้องดำเนินการเพื่อป้องกันมิให้ผู้นั้นใช้หรือเปิดเผยข้อมูลส่วนบุคคลโดยปราศจากอำนาจหรือโดยมิชอบ
                          </v-container>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-row>
                        <v-col cols="12" sm="2">
                          <v-btn color="white" fab x-large>
                            <v-icon x-large color="indigo darken-4">mdi-star-circle-outline</v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="12" sm="10" class="text-left">
                          <v-container grid-list-xs>
                            <h3>การทบทวนมาตรการรักษาความมั่นคงปลอดภัย</h3>ดำเนินการทบทวนมาตรการรักษาความมั่นคงปลอดภัย
                            เมื่อมีความจำเป็นหรือเมื่อเทคโนโลยีเปลี่ยนแปลงไปเพื่อให้
                            มีประสิทธิภาพในการรักษาความมั่นคงปลอดภัยที่เหมาะสม
                            ดำเนินการอย่างน้อยปีละ 1 ครั้ง หรือให้เป็นไปตามมาตรฐาน
                            ขั้นต่ำที่คณะกรรมการคุ้มครองข้อมูลส่วนบุคคลประกาศกำหนด
                          </v-container>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12">
                          <v-container grid-list-xs>
                            <center>
                              <v-img :src="require('../../assets/img/image1.png')" width="35%" />
                            </center>
                          </v-container>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </v-container>
            </section>
            <section class="indigo lighten-5 pt-7 pb-1" style="color:#1A237E">
              <v-container>
                <v-row dense>
                  <v-col cols="12" sm="2" align="center" justify="center">
                    <v-container grid-list-xs>
                      <v-img :src="require('../../assets/img/image2.png')" width="70%" />
                    </v-container>
                  </v-col>
                  <v-col cols="12" sm="10">
                    <v-container grid-list-xs>
                      <h3 class="pb-3">ขั้นตอนการแจ้งเหตุการณ์ละเมิดข้อมูลส่วนบุคคล</h3>จัดให้ขั้นตอนการแจ้งเหตุการณ์ละเมิดข้อมูลส่วนบุคคล (PII data Breach)
                      แก่สำนักงานโดยไม่ชักช้าภายใน72 ชั่วโมงตามมาตรฐานนับแต่ทราบเหตุเท่าที่จะสามารถกระทำได้
                      เว้นแต่การละเมิดดังกล่าวไม่มีความเสี่ยงที่จะมีผลกระทบต่อสิทธิและเสรีภาพของบุคคล
                      ในกรณีที่การละเมิดข้อมูลส่วนบุคคล มีความเสี่ยงสูงที่จะมีผลกระทบต่อสิทธิและเสรีภาพของบุคคล
                      ให้แจ้งเหตุการละเมิดให้เจ้าของข้อมูลส่วนบุคคลทราบพร้อมกับแนวทางการเยียวยา โดยไม่ชักช้าด้วย
                      หรือการแจ้งดังกล่าวและข้อยกเว้นให้เป็นไปตามหลักเกณฑ์และวิธีการที่
                      คณะกรรมการประกาศกำหนด
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </section>
            <section class="white pt-6 pb-4" style="color:#1A237E">
              <v-container grid-list-xs>
                <div align="center" justify="center">
                  <v-container grid-list-xs>
                    <h2>ระบบการตรวจสอบเพื่อดำเนินการลบหรือทำลายข้อมูลส่วนบุคคล</h2>
                    <v-container grid-list-xs>
                      <div class="text-left pt-2">
                        จัดให้มีระบบการตรวจสอบเพื่อดำเนินการลบหรือทำลายข้อมูลส่วนบุคคลเมื่อพ้นกำหนด
                        ระยะเวลาการเก็บรักษา หรือที่ ไม่เกี่ยวข้องหรือเกินความจำเป็นตามวัตถุประสงค์ในการเก็บรวบรวม
                        ข้อมูลส่วนบุคคลนั้น หรือตามที่เจ้าของข้อมูลส่วนบุคคล ร้องขอ หรือที่เจ้าของข้อมูลส่วนบุคคล
                        ได้ถอนความยินยอม เว้นแต่เก็บรักษาไว้เพื่อวัตถุประสงค์ในการใช้เสรีภาพในการแสดงความคิดเห็น
                        การเก็บรักษาไว้เพื่อวัตถุประสงค์ ดังนี้
                      </div>
                    </v-container>
                  </v-container>
                  <v-row>
                    <v-col cols="12" sm="12" class="fill-height">
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-container grid-list-xs>
                            <sequential-entrance fromRight delay="100">
                              <v-card
                                class="mx-auto"
                                max-width="460"
                                :height="210"
                                max-height="400"
                              >
                                <v-card-text style="color:#1A237E">
                                  <v-row>
                                    <v-col cols="12" sm="1">
                                      <v-icon color="indigo darken-4">mdi-gamepad-circle-left</v-icon>
                                    </v-col>
                                    <v-col cols="12" sm="11" class="text-left">
                                      เพื่อให้บรรลุวัตถุประสงค์ที่เกี่ยวกับการจัดทำเอกสารประวัติศาสตร์หรือจดหมายเหตุ
                                      เพื่อประโยชน์สาธารณะ
                                      หรือที่เกี่ยวกับการศึกษาวิจัยหรือสถิติซึ่งได้จัดให้มีมาตรการปกป้องที่เหมาะสม
                                      เพื่อคุ้มครองสิทธิและเสรีภาพของเจ้าของข้อมูลส่วนบุคคล
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </sequential-entrance>
                            <br />
                            <sequential-entrance fromRight delay="100">
                              <v-card class="mx-auto" max-width="460">
                                <v-card-text style="color:#1A237E">
                                  <v-row>
                                    <v-col cols="12" sm="1">
                                      <v-icon color="indigo darken-4">mdi-gamepad-circle-up</v-icon>
                                    </v-col>
                                    <v-col cols="12" sm="11" class="text-left">
                                      เวชศาสตร์ป้องกันหรืออาชีวเวชศาสตร์ การประเมินความสามารถในการทำงานของลูกจ้าง
                                      การวินิจฉัยโรคทางการแพทย์ การให้บริการด้านสุขภาพหรือด้านสังคม การรักษาทางการแพทย์
                                      การจัดการด้านสุขภาพ หรือระบบและการให้บริการด้านสังคมสงเคราะห์
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                                <v-card-actions>
                                  <v-btn color="indigo darken-4" text>อ่านเพิ่มเติม</v-btn>

                                  <v-spacer></v-spacer>

                                  <v-btn icon @click="showDataFour = !showDataFour">
                                    <v-icon>{{ showDataFour ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                  </v-btn>
                                </v-card-actions>

                                <v-expand-transition>
                                  <div v-show="showDataFour">
                                    <v-divider></v-divider>
                                    <v-card-text style="color:#1A237E">
                                      ทั้งนี้ ในกรณีที่ไม่ใช่การปฏิบัติ ตามกฎหมายและข้อมูลส่วนบุคคลนั้นอยู่ในความรับผิดชอบของผู้ประกอบอาชีพหรือวิชาชีพหรือผู้มีหน้าที่
                                      รักษาข้อมูลส่วนบุคคลนั้นไว้เป็นความลับตามกฎหมาย ต้องเป็นการปฏิบัติตามสัญญาระหว่างเจ้าของข้อมูลส่วนบุคคลกับผู้ประกอบวิชาชีพทางการแพทย์
                                    </v-card-text>
                                  </div>
                                </v-expand-transition>
                              </v-card>
                            </sequential-entrance>
                          </v-container>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-container grid-list-xs>
                            <sequential-entrance fromRight delay="100">
                              <v-card class="mx-auto" max-width="460" :height="210">
                                <v-card-text style="color:#1A237E">
                                  <v-row>
                                    <v-col cols="12" sm="1">
                                      <v-icon color="indigo darken-4">mdi-gamepad-circle-right</v-icon>
                                    </v-col>
                                    <v-col cols="12" sm="11" class="text-left">
                                      เป็นการจำเป็นเพื่อการปฏิบัติหน้าที่ในการดำเนินภารกิจเพื่อประโยชน์สาธารณะของผู้ควบคุมข้อมูลส่วนบุคคล
                                      หรือปฏิบัติหน้าที่ในการใช้อำนาจรัฐที่ได้มอบให้แก่ผู้ควบคุมข้อมูลส่วนบุคคล
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </sequential-entrance>
                            <br />
                            <sequential-entrance fromRight delay="100">
                              <v-card class="mx-auto" max-width="460" max-height="400">
                                <v-card-text style="color:#1A237E">
                                  <v-row>
                                    <v-col cols="12" sm="1">
                                      <v-icon color="indigo darken-4">mdi-gamepad-circle-down</v-icon>
                                    </v-col>
                                    <v-col cols="12" sm="11" class="text-left">
                                      ประโยชน์สาธารณะด้านการสาธารณสุข เช่น การป้องกันด้านสุขภาพจากโรคติดต่อ
                                      อันตรายหรือโรคระบาดที่อาจติดต่อหรือแพร่เข้ามาในราชอาณาจักร
                                      หรือการควบคุมมาตรฐานหรือคุณภาพ ของยา เวชภัณฑ์ หรือเครื่องมือแพทย์
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                                <v-card-actions>
                                  <v-btn color="indigo darken-4" text>อ่านเพิ่มเติม</v-btn>

                                  <v-spacer></v-spacer>

                                  <v-btn icon @click="showDataFive = !showDataFive">
                                    <v-icon>{{ showDataFive ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                  </v-btn>
                                </v-card-actions>

                                <v-expand-transition>
                                  <div v-show="showDataFive">
                                    <v-divider></v-divider>
                                    <v-card-text style="color:#1A237E">
                                      ได้จัดให้มีมาตรการที่เหมาะสมและเจาะจงเพื่อคุ้มครองสิทธิ
                                      และเสรีภาพของเจ้าของข้อมูลส่วนบุคคลโดยเฉพาะการรักษาความลับของข้อมูลส่วนบุคคลตามหน้าที่หรือ
                                      ตามจริยธรรมแห่งวิชาชีพ
                                    </v-card-text>
                                  </div>
                                </v-expand-transition>
                              </v-card>
                            </sequential-entrance>
                          </v-container>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </v-container>
            </section>
            <section class="indigo lighten-5 pt-5 pb-5" style="color:#1A237E">
              <v-container>
                <v-row dense>
                  <v-col cols="12" sm="10" class="text-left">
                    <v-container grid-list-xs>
                      <h3 class="pb-3">เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล</h3>จัดให้มีเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล
                      (Data Personal Officer) อย่างเป็นลายลักษณ์อักษร เพื่อบริหารจัดการและคุ้มครองข้อมูลส่วนบุคคล
                      อาธิเช่น การให้คำแนะนำให้ปฏิบัติตาม การตรวจสอบการดำเนินการของบริษัทที่เกี่ยวข้องกับการเก็บรวบรวม
                      ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล การประสานงานและให้ความร่วมมือสำนักงาน การรักษาความลับของข้อมูลส่วนบุคคลที่ล่วงรู้
                      และการรายงานให้ผู้บริการสูงสุดขององค์กรทราบกรณีเกิดปัญหา ให้เป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
                    </v-container>
                  </v-col>
                  <v-col cols="12" sm="2" align="center" justify="center">
                    <v-container grid-list-xs>
                      <v-img :src="require('../../assets/img/image3.png')" width="75%" />
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </section>
          </v-content>

          <Footer />
        </v-card>
      </v-content>
    </v-app>
  </div>
</template>

<script>
import Navbar from "./layout-landingpage-navbar";
import Footer from "./layout-landingpage-footer";

export default {
  components: { Footer, Navbar },
  data: () => ({
    showDataOne: false,
    showDataTwo: false,
    showDataThree: false,
    showDataFour: false,
    showDataFive: false,
    isbgDesktop: true,
    isbgMobile: false
  }),
  methods: {
    onScroll() {
      const showsize = window.innerWidth;
      this.isbgDesktop = showsize > 600;
      this.isbgMobile = showsize < 600;
    }
  }
};
</script>

<style>
@import "../../assets/css/data.css";
</style>
